@import url("https://use.typekit.net/eeg4gai.css");

// $blueCluby: '#1775F3';
// $blueLightText: '#5798EF';
// $green: '#5798EF';
// $white: '#FFFFFF';
// $black: '#000000';
$red: #C43359;
// $grey: '#555555';

// $background: '#202020';

// $primaryText: '#FFFFFF';
// $secondaryText: '#AAAAAA';
$stroke: #353535;
$inputBackground: #303030;
$boxFill: #252525;
$background: #202020;
$lightGrey: #BDBDBD;
$primary: #FFFFFF !default;
$secondary: #AAAAAA !default;
$secondaryText: #FFFFFF70 !default;
$success: $primary !default;
$info: $primary !default;
$warning: $primary !default;
$danger: $primary !default;
$light: #f8f9fa !default;
$dark: #000000 !default;
$green: #2E6E56;

$light-border-color: $boxFill !default;

$border-radius: 0.3rem !default;

$card-cap-bg: $background !default;
$card-border-color: $light-border-color !default;
$card-cap-color: $primary !default;

//$font-family-base: 'proxima-nova' !default;
$font-list: 'DM Sans', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
$font-family-base: $font-list !default;

$list-group-border-color: $light-border-color !default;

h1, h2, h3, h4, h5, h6, div {
  color: $primary;
}

$body-bg: $background;

.navbar-auracard {
  //padding: 0px !important;
  background-color: $background !important;
  //border-bottom: 1px solid #ddd;
  //height: 60px !important;
  //background-color: #FF0000 !important;

  .navbar-brand {
    padding: 0;
    //color: red !important;
    //background-color: green !important;
  }

  .navbar-nav {
    background-color: $background;

    color: $primary;

    .nav-link {
      padding-top: 20px;
      padding-bottom: 15px;
      //height: 60px;
      margin-left: 5px;
      margin-right: 5px;
      color: $primary;
    }

    .nav-link.active {
      color: $primary !important;
      border-bottom: 2px solid $primary;
      font-weight: bold;
    }

    .dropdown-item.active {
      background-color: unset;
      color: $primary;
    }

  }

}

.input-group-append {
  span {
    color: $primary !important;
    background: $inputBackground !important;
  }
}

.dropdown-menu {
  background-color: $inputBackground !important;
  color: $primary !important;
  .active {
    background-color: $primary !important;
    color: $dark !important;
  }
}

.dropdown-item {
  color: $primary !important;
}

.dropdown-item:hover {
  background-color: $secondary !important;
  color: $dark !important;
}

.dropdown-divider {
  border-color: $stroke !important;
}

.react-select_message_placeholder .react-select__placeholder {
  color: $primary !important;
  font-weight: bold !important;
  text-align: center !important;
  width: 100%;
  opacity: 0.9;
}

.react-select__multi-value__remove {
  color: $dark;
}

.react-select__control {
  background-color: $inputBackground !important;
  border-color: $stroke !important;
}

.react-select__menu {
  background-color: $boxFill !important;
  border-color: $stroke !important;
}

.react-select__placeholder {
  color: $secondary !important;
}

.modal-content {
  background-color: $boxFill !important;
  color: $primary;
}

.modal-header {
  border-color: $stroke !important;
}

.modal-footer {
  border-color: $stroke !important;
}

.close {
  color: $primary !important;
}

.custom-switch {
  background-color: transparent !important;
  color: $primary !important;
}

.custom-control-input:checked ~ .custom-control-label::before {
  background-color: $primary !important;
}

.custom-control-input:not(:checked) ~ .custom-control-label::before {
  background-color: $secondary !important;
}

.custom-control-input:checked ~ .custom-control-label::after {
  background-color: $green !important;
}

.custom-control-input:not(:checked) ~ .custom-control-label::after {
  background-color: $primary !important;
}

.react-select-container-benefits-statuses {
  width: 100% !important;

  .react-select__value-container {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
  }
  .react-select__clear-indicator {
    display: none !important;
  }
  .react-select__placeholder {
    font-size: 14px !important;
  }
}


.react-select__control--is-focused {
  border-color: $secondary !important;
  -webkit-box-shadow: 0 0 0 0.2rem transparent !important;
  -moz-box-shadow: 0 0 0 0.2rem transparent !important;
  box-shadow: 0 0 0 0.2rem transparent !important;
}

.react-select__option--is-focused {
  background-color: $background !important;
}

.react-select__option--is-selected {
  background-color: $background !important;
}

.bg-disabled {
  //background-color: #eee !important;
  border-color: $stroke !important;
  background: repeating-linear-gradient(
                  45deg,
                  $background,
                  $background 10px,
                  $stroke 10px,
                  $stroke  20px
  ) !important;
}

.bg-disabled-button {
  //background-color: #eee !important;
  border-color: $stroke !important;
  background: repeating-linear-gradient(
                  45deg,
                  $background,
                  $background 10px,
                  $stroke  10px,
                  $stroke  20px
  ) !important;
  &:hover {
    color: $primary !important;
  }
}



.menuGray {
  color: $primary !important;
  font-size: 15px;
  .dropdown-toggle::after {
    color: #666 !important;
    text-decoration-color: red;
  }
}

.text-green {
  color: $green;
}

.activeMenu {
  color: red;
  background: $green;
}

.formContainer {
  border-color: $stroke !important;
}

.primaryInput, input, textarea, select {
  border-color: $stroke !important;
  color: $primary !important;
  background-color: transparent !important;
}

.greenInput {
  border-color: $green !important;
  color: $green !important;
  &:hover {
    border-color: transparent !important;
    color: white !important;
  }
}



.primaryInputGroupText {
  border-color: $stroke !important;
  color: $stroke !important;
  background-color: $background !important;
}

.font-size-large {
  font-weight: 400 !important;
  font-size: 2rem;
}

.font-size-larger {
  font-size: 1.5rem;
}

.font-size-medium {
  font-size: 1.1rem;
}

.font-size-normal {
  font-size: 1.0rem;
}

.font-size-small {
  font-size: 0.9rem;
}

.icon-medium {
  font-size: 1.3rem;
}

.text-menugray {
  color: $secondary !important;
}

.text-hentovihrea {
  color: $green !important;
}

.text-hentopinkki {
  color: $secondary !important;
}

.scrollArrowCluby {
  opacity: 0;
}

.scrollArrowCluby:hover {
  opacity: 1;
}

.summary-card-wrapper {
  display: flex;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none; /* Hide scrollbar for Webkit browsers */
}


.summary-box {
  flex-grow: 1;
  display: flex !important;
  flex-direction: column !important;
  justify-content: space-between !important;
  align-items: center !important;
  padding: 5px !important;

  .summary-box-venue {
    color: $boxFill;
    width: 130px;
    margin-bottom: 0;
    margin-top: 0;
    padding-top: 5px;
    padding-bottom: 2px;
    font-size: 10px;
  }

  .summary-box-title {
    width: 130px;
    padding-left: 5px;
    padding-right: 5px;
    //height: 55px;
    display: flex;
    flex-grow: 0;
    align-items: center;
    justify-content: center;
    //background-color: #ddd;
  }

  .summary-box-title:first-child {
    height: 55px;
    flex-grow: 1;
    //background: red;
  }

  .summary-box-value {
    height: 45px;
    display: flex;
    flex-grow: 0;
    align-items: center;
    justify-content: center;
    //background-color: #ccc;
  }

  .summary-box-footer {
   max-width: 160px;

    min-height: 30px;
    max-height: 45px;
    display: flex;
    flex-direction: column;
    flex-grow: 0;
    align-items: center;
    justify-content: center;
    color: $boxFill;
    padding-left: 3px;
    padding-right: 3px;
    //background-color: #eee;
  }

  display: flex !important;
  align-items: center !important;
  flex-direction: column !important;
  justify-content: flex-end !important;
  padding: 5px !important;
  //padding-top: 10px !important;
  //padding-left: 10px !important;
  //padding-right: 10px !important;
}

.container, .container-fluid {
  max-width: 1000px !important;
  //width:1000px !important;
  min-width: 1000px !important;
  //background-color: #ff00ff;
}

.container .container {
  //background-color: #bfafdd;
  //background-color: #ff0000 !important;
  min-width: unset !important;
  max-width: unset !important;
  //min-width: 100% !important;
  //max-width: 100% !important;
}

.container-fluid .container-fluid {
  background-color: red;
}

.col {
  margin: 0 !important;
  padding: 0 !important;
}

.input-group-prepend {
    color: $primary !important;
}

.input-group-text {
  background-color: $inputBackground !important;
  border-color: $stroke !important;
  color: $primary !important;
}

.form-control {
  background-color: $inputBackground !important;
  border-color: $stroke !important;
}

input:focus, input:focus-visible {
  border-color: $secondary !important;
  -webkit-box-shadow: 0 0 0 0.2rem transparent !important;
  -moz-box-shadow: 0 0 0 0.2rem transparent !important;
  box-shadow: 0 0 0 0.2rem transparent !important;
}

tr {
  background-color: $boxFill !important;
}

td {
  border-color: transparent !important;
}

.card-body, table, .rounded {
  border: 1px solid $stroke !important;
}

.card-body {
  background-color: $boxFill !important;

  color: $primary;
  @extend .pl-4;
  @extend .pr-4;
}

.smallCardPadding .card-body {
  @extend .pl-2;
  @extend .pr-2;
}

.card-title {
  text-transform: uppercase !important;
}

.card {
  background-color: $boxFill !important;
  max-width: 980px !important;

  .container, .container-fluid {
    //background-color: #9c3ac5;
    min-width: unset !important;
    max-width: unset !important;
  }
}

.row .card {
  min-width: unset !important;
  max-width: unset !important;
}

.modal-title {
  color: $primary !important;
}

.breakLine {
  border-color: $stroke !important;
}

.modal-body {
  @extend .font-size-medium;
}

$grid-breakpoints: (
        xs: 0,
        sm: 1px,
        md: 2px,
        lg: 3px,
        xl: 4px
) !default;

$btn-white-space: nowrap !default;

.btn {
  white-space: nowrap;

}

.btn:not([disabled]):not(.btn-link):hover {
  background-color: $primary !important;
  color: $dark !important;
  border-color: $primary !important;
}


.btn-link {
  &:hover {
    background: transparent !important;
    background-color: transparent !important;
    color: $primary !important;
    border-color: transparent !important;
  }
}

.btn-text {
  color: $secondary !important;
  background-color: transparent !important;
  border-color: transparent !important;
}

.btn-outline-success:hover {
  background-color: $green !important;
  color: $primary !important;
  border-color: $green !important;
}

.btn-transparent {
  color: $primary !important;
}


.btn-primary:disabled, .btn-primary:disabled:hover {
  color: $primary !important;
  border-color: $secondary !important;
  background-color: $secondary !important;
}

.btn-secondary {
  background-color: $stroke !important;
  border-color: $stroke !important;
  color: $primary !important;
}


.btn-outline-primary:disabled {
  opacity: 0.65 !important;
  border-color: $secondary !important;
  color: $secondary !important;
}

.btn-outline-primary, .btn-outline-secondary {
  border-color: $primary !important;
  color: $primary !important;
}


input::placeholder {
  color: $secondary !important;
}

input.textInput:disabled, select.textInput:disabled {
  opacity: 0.65;
}

td {
  vertical-align: middle !important;
}

table {
  @extend .font-size-medium;
}

tr {
  border-color: red !important;
}

th, td {
  text-align: center !important;
  color: $primary;
}

.nav-pills {
  .nav-link {
    background-color: transparent;
    color: $secondary;
  }
  .active {
    background-color: transparent !important;
    // color: transparent !important;
    color: $primary;
  }
}

.alert {
  background-color: transparent !important;
  border-color: $stroke !important;
}

.nav-tabs {
  border-color: transparent !important;
  .nav-link {
    @extend .font-size-medium;
    color: $primary !important;
    border-color: transparent !important;
  }

  .nav-link.active {
    background-color: transparent !important;
    border-color: transparent !important;
    font-weight: bold !important;
    color: $primary !important;
    //background-color: green !important;
  }
}

.nav-tabs-transparent   {
  .nav-item, .nav-link {
    background-color: transparent !important;
  }
}

.nav-tabs .nav-item {
  background-color: $boxFill !important;
  // border-bottom: 2px solid transparent !important;
  color: $primary !important;
  border: 0px;
  .nav-link.active {
    background-color: $stroke !important;
    color: $primary !important;
    font-weight: bold  !important;
    // border-bottom: 2px solid transparent !important;
  }
  .nav-link:hover {
    background-color: $inputBackground !important;
    // opacity: 0.5
  }
}

.nav-tabs {
  border: none !important;
  .nav-item, .nav-link {
    background-color: $boxFill;

    &:first-child {
      border-top-left-radius: 5px;
    }

    &:last-child {
      border-top-right-radius: 5px;
    }
  }
}

.nav-tabs.nav-item:hover, .nav-tabs.nav-link {
  border-color: $boxFill !important;
  background-color: $secondary !important;
  color: $primary !important;
}

$table-accent-bg: $primary !default;

.benefitTable {
  th:nth-child(2) {
    text-align: left !important;
  }

  th:nth-child(6) {
    text-align: left !important;
  }
}

.memberTable {
  th:nth-child(1) {
    text-align: center !important;
    padding-right: 30px !important;
  }

  th:nth-child(6) {
    text-align: left !important;
  }
}

.loading-dim {
  z-index: 9999999;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  background-color: $background;
  opacity: 0.5;
  overflow: hidden;
  position: fixed;
}

.edit-list {
  pointer-events: none !important;
  user-select: text !important;
  -webkit-user-select: text !important;
  border-radius: 5px !important;

  a {
    pointer-events: auto !important;
  }

  &:hover {
    background-color: $boxFill !important;
  }
}

.hover-underline {
  &:hover {
    text-decoration: underline;
  }
}

.datePicker {
  color: $primary !important;
  border-color: $stroke !important;
}

input[type="time"] {
  color-scheme: dark;
}

.react-datepicker-popper {
  background-color: $inputBackground !important;
  border-color: $inputBackground !important;

  .react-datepicker__time {
    background-color: $inputBackground !important;
  }

  .react-datepicker__triangle::before,
  .react-datepicker__triangle::after, .react-datepicker__triangle {
    border-bottom-color: $inputBackground !important;
    border-top-color: $inputBackground !important;
  }

  .react-datepicker {
    background-color: $inputBackground;
    color: $primary;
    border-color: $inputBackground !important;



    .react-datepicker-time__header {
      color: $primary !important;
    }

    .react-datepicker__header {
      background-color: $inputBackground;
      border-bottom-color: $inputBackground;

    }

    .react-datepicker__current-month,
    .react-datepicker__day-name,
    .react-datepicker__day,
    .react-datepicker__time-list-item {
      color: $primary;
      background-color: $inputBackground;
    }
    .react-datepicker__time-list-item:hover {
      color: $dark !important;
      background-color: $secondary !important;
    }

    .react-datepicker__day--selected,
    .react-datepicker__day--keyboard-selected,
    .react-datepicker__time-list-item--selected {
      background-color: $primary !important;
      color: $inputBackground !important;
    }

    .react-datepicker__day--disabled,
    .react-datepicker__time-list-item--disabled {
      color: $secondary;
    }

    .react-datepicker__navigation {
      top: 10px;
      color: $primary;

    }

    .react-datepicker__navigation--previous {
      border-right-color: $primary;
    }

    .react-datepicker__navigation--next {
      border-left-color: $primary;
    }



    .react-datepicker__navigation-icon::before {
      border-color: $inputBackground !important;
    }
  }
}

.rdrDateRangeWrapper,
.rdrDateDisplayWrapper,
.rdrDefinedRangesWrapper,
.rdrDateRangePickerWrapper,
.rdrStaticRanges,
.rdrDateDisplay,
.rdrDateDisplayWrapper,
.rdrCalendarWrapper {
  background-color: $inputBackground !important;
  color: $primary !important;
  border-color: transparent !important;
}

.rdrStaticRange:hover {
  background-color: $inputBackground !important;
}

.rdrStaticRange {
  background-color: $inputBackground !important;
  color: $primary !important;
  border-color: $inputBackground !important;
}

.rdrDateDisplayItem {
  background: $boxFill !important;
  border-color: $stroke !important;
}

.rdrDayHovered {
  border-color: $lightGrey;
}

.rdrDayNumber span,
.rdrMonthAndYearWrapper,
.rdrNextPrevButton {
  color: $primary !important;
}

.rdrDayHovered .rdrDayNumber,
.rdrDayHovered .rdrDayNumber,
.rdrDayHovered .rdrDayNumber,
.rdrDayHovered .rdrDayNumber  {
  color: red !important;
}

.rdrDayDisabled {
  color: $secondary !important;
}


.rdrDayStartPreview,
.rdrDayEndPreview,
.rdrDayInPreview {
  // background: $secondary !important;
  color: $primary !important;
}

.rdrSelected,
.rdrInRange,
.rdrStartEdge,
.rdrEndEdge {
  background-color: $secondary !important;
}

.rdrDayDisabled {
  background-color: transparent !important;
}

.rdrDayHovered {
  border-color: $lightGrey !important;
}

.date-range-dropdown-menu .btn-secondary {
  background-color: $secondary !important;
  color: $primary !important;
}

.rdrStaticRangeLabel:hover {
  background-color: $stroke !important;
}

.date-range-dropdown-menu .btn {
  border-color: $stroke !important;
}

*::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

*::-webkit-scrollbar-thumb {
  background-color: $secondary;
  border-radius: 6px;
}

*::-webkit-scrollbar-track {
  background-color: $stroke;
}

// BOOTSTRAP OVERRIDE
.list-group-horizontal .list-group-item + .list-group-item {
  border-left-width: 1px !important;
}

.list-group-item {
  background-color: $boxFill !important;
  color: $primary !important;
  border-color: $stroke !important;
}

// Override default variables before the import


// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';
@import "~bootstrap/scss/mixins";

.btn-hentovihrea {
  $button-color: $green;
  @include button-outline-variant($button-color);

  &:not(:disabled) {
    background-color: $button-color !important;
    color: white !important;

    &:hover {
      background-color: darken($button-color, 7.5%) !important;
      border-color: darken($button-color, 7.5%) !important;
    }
  }

  color: $button-color !important;
  border-color: $button-color !important;
}
