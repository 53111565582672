.textInput {
  height: 32px;
}
.textInputLabel {
  font-size: 14px;
}

.formContainer {
  border: solid 1px #ced4da;
  border-radius: 5px;
  padding: 24px !important;
  padding-bottom: 48px !important;
}

.clubyHandleInput {
  display: inline-block;
}

.imageUploadModal {
  width: 50vw !important;
  max-width: 5000px !important;
}

.croppedLogo {
  height: 200px;
  width: 200px;
  border-radius: 50% !important;
  object-fit: cover;
}

.croppedCover {
  height: 198px;
  width: 330px;
}

.arrow-icon {
  transition: transform 0.3s ease-in-out;
}

.rotate-right {
  transform: rotate(90deg);
}

.rotate-down {
  transform: rotate(180deg);
}

.gps-wrapper {
  display: grid;
  grid-template-rows: 0fr;
  transition: grid-template-rows 0.3s ease-in-out;
}

.gps-wrapper.is-open {
  grid-template-rows: 1fr;
}

.inner {
  overflow: hidden;
}

.close-btn {
  position: absolute;
  top: 8px;
  right: 8px;
  width: 42px;  /* Ensure minimum tap target */
  height: 42px; /* Ensure minimum tap target */
  display: flex; /* Center the icon */
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  border-radius: 50%; /* Optional: circular shape */
  cursor: pointer;
  color: #6c757d;
  transition: background 0.3s ease-in-out, color 0.2s;
}

.close-btn:hover {
  background: rgba(220, 53, 69, 0.1); /* Light red background */
  color: #dc3545; /* Bootstrap danger red */
}

h6 {
  border-bottom: 1px solid #353535;
  margin-top: 1rem !important;
  padding-bottom: 0.25rem !important;
  margin-bottom: 1.0rem !important;
  text-transform: uppercase;
}

h4.hiddenVenue {
  font-style: italic;
}

.location-item {
  padding: 0 !important;
  overflow: hidden;
  position: relative;
}

.location-item .gutter {
  background-color: #353535;
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-width: 10%;
  color: white;
}

button.gutter {
  transition: background-color 240ms ease-in;
  background-color: #353535;
  border: 0;
}

button.destructive:hover {
  background-color: #dc3545;
}

button.add-location {
  transition: background-color 240ms ease-in;
  background-color: #353535;
}
button.add-location:hover {
  background-color: rgba(128, 128, 128, 0.6) !important;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(128, 128, 128, 0.6); /* Grey overlay */
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s ease-in-out; /* Smooth hover effect */
}

.location-data {
  position: relative;
  flex: 1;
  padding: 1rem;
  background-color: unset;
  border: 0;
  text-align: left;
}
.location-data:hover .overlay {
  opacity: 1;
}

.category-input {
  z-index: 2;
  position: relative;
}

// This div specifier helps override the .container .container directive in the global styling
// by making the CSS more specific and thus more important
div.location-container {
  min-width: unset !important;
  max-width: unset !important;
  flex: 1 1 50%;
}


.throbber {
  width: 20px;
  height: 20px;
  display: inline-block;
  position: relative;
}
.throbber::after,
.throbber::before {
  content: '';
  box-sizing: border-box;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid #FFF;
  position: absolute;
  left: 0;
  top: 0;
  animation: animloader 2s linear infinite;
}
.throbber::after {
  animation-delay: 1s;
}

@keyframes animloader {
  0% {
    transform: scale(0);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}
