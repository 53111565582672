.textInput {
  height: 32px;
}
.textInputLabel {
  font-size: 14px;
}

.formContainer {
  border: solid 1px #ced4da;
  border-radius: 5px;
  padding: 24px !important;
  padding-bottom: 48px !important;
}

.clubyHandleInput {
  display: inline-block;
}

.imageUploadModal {
  width: 50vw !important;
  max-width: 5000px !important;
}

.croppedLogo {
  height: 200px; 
  width: 200px;
  border-radius: 50% !important;
  object-fit: cover;
}

.croppedCover {
  height: 198px; 
  width: 330px;
}