/*
.App {
    font-size: 0.80rem;
}


.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
*/

.App {
  background-color: #1f1f1f;
  min-height: 100vh;
}

.app-container {
  min-height: calc(100vh - 56px);
  display: flex;
  padding-top: 36px;
  width: 100%;
  max-width: 1250px;
  margin-left: auto;
  margin-right: auto;
}

/* Style for fixed navbar */
.navbar.navbar {
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
  z-index: 1030 !important;
  background-color: #1f1f1f !important; 
  height: 56px !important;
  border-bottom: 1px solid #2a2a2a;
}

.navbar .container {
  max-width: 1250px !important;
  width: 100% !important;
}

/* Navbar text and elements color */
.navbar .nav-link,
.navbar .navbar-brand,
.navbar .dropdown-toggle,
.menuGray {
  color: white !important;
}

.dropdown-menu {
  background-color: #2a2a2a;
  border-color: #444;
}

.dropdown-menu .dropdown-item {
  color: #e0e0e0;
}

.dropdown-menu .dropdown-item:hover,
.dropdown-menu .dropdown-item:focus {
  background-color: #3a3a3a;
  color: white;
}

.dropdown-menu .dropdown-item.active {
  background-color: #007bff;
  color: white;
}

.w-100 {
  width: 100%;
}
