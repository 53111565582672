.timeline-item {
    position: relative;
    padding-left: 20px;
    margin-bottom: 20px;
  }
  
  .timeline-dot {
    position: absolute;
    left: 0;
    top: 20px;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: #AAAAAA;
  }
  
  .timeline-line {
    position: absolute;
    left: 2px;
    top: 38px;
    width: 2px;
    height: calc(100% - 10px);
    background-color: #AAAAAA;
    opacity: 0.5;
  }