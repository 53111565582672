.app-container {
  display: flex;
  width: 100%;
  position: relative;
}

.side-navbar {
  width: 240px;
  height: calc(100vh - 56px);
  position: fixed;
  top: 56px;
  left: 0;
  background-color: #1f1f1f;
  z-index: 900;
  padding-top: 15px;
  overflow-y: auto;
  border-right: 1px solid #2a2a2a;
}

.side-nav-link {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  color: #e0e0e0;
  transition: all 0.2s ease;
  margin-bottom: 5px;
  border-radius: 5px 0 0 5px; 
}

.side-nav-link:hover, 
.side-nav-link:focus {
  background-color: rgba(255, 255, 255, 0.08);
  color: white;
}

.side-nav-link.active {
  background-color: rgba(255, 255, 255, 0.15);
  color: white;
  font-weight: 500;
 
}

.side-nav-link.active .side-nav-icon {
  font-weight: 900;
  transform: scale(1.1);
}

.side-nav-icon {
  width: 20px;
  text-align: center;
  margin-right: 12px;
  transition: all 0.2s ease;
}

.side-nav-text {
  font-size: 0.95rem;
}

.main-content-with-sidebar {
  margin-left: 240px;
  padding: 15px 20px;
  width: calc(100% - 240px);
  min-height: calc(100vh - 56px);
  background-color: #1f1f1f;
  color: white;
}

@media (min-width: 1400px) {
  .app-container {
    max-width: 1250px;
    margin: 0 auto;
  }
  
  .side-navbar {
    left: 50%;
    margin-left: -625px;
  }
  
  .main-content-with-sidebar {
    margin-left: 240px;
    max-width: calc(1250px - 240px);
  }
}

@media (max-width: 1300px) {
  .side-navbar {
    width: 70px;
    border-right: 1px solid #2a2a2a;
  }
  
  .side-nav-text {
    display: none;
  }
  
  .side-nav-link {
    justify-content: center;
    padding: 10px 0;
    text-align: center;
  }
  
  .side-nav-icon {
    margin-right: 0;
  }
  
  .main-content-with-sidebar {
    margin-left: 70px;
    width: calc(100% - 70px);
  }
} 