.datePicker {
  border: 1px solid #ced4da;
  border-radius: 0.3rem;
  color: #495057 !important;
}

.datePickerParent {
  & .react-datepicker-popper {
    z-index: 9999 !important;
  }
}